import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Started in 2011,
Microbeats is an experiment in music production emphasizing quantity over quality.
All beats are created by `}<a parentName="p" {...{
        "href": "https://jxnblk.com"
      }}>{`Jxnblk`}</a>{` in under an hour and not mixed down or mastered.`}</p>
    <p>{`Read more: `}<a parentName="p" {...{
        "href": "https://jxnblk.com/blog/microbeats-is-the-best-journal-ive-ever-kept/"
      }}>{`Microbeats is the Best Journal I've Ever Kept`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      